body, *{
  margin: 0;
  padding: 0;
}
body {
  background: #D6D1C4;
}


/* Under construction  */
.underConstruction{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.underConstruction h3{
margin-top: 10px;
}


li{
  list-style: none;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem; /* Equivalent to h-32 */
}

.img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem; /* Adjust size as needed */
  height: 3rem; /* Adjust size as needed */
  background-color: #fff; /* Background color */
  border-radius: 50%; /* Make it a circle */
  font-size: 2rem; /* Font size of the initial */
  font-weight: bold; /* Bold font */
  color: white; /* Text color */
  text-align: center; /* Center the text horizontally */
}

.initials-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #000000;
  border-radius: 50%;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
}
.customer-name-box .text-box{
  margin-left: 0.5rem;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Equivalent to space-x-2 */
}

.loader-dot {
  width: 1rem; /* Equivalent to w-4 */
  height: 1rem; /* Equivalent to h-4 */
  background-color: #3b82f6; /* Equivalent to bg-blue-500 */
  border-radius: 50%; /* Equivalent to rounded-full */
  animation: bounce 0.5s infinite alternate; /* Equivalent to animate-bounce */
}
@font-face {
  font-family: 'halyard-medium';
  src: url(../fonts/halyard/Halyard-Micro-Medium.ttf);
}
@font-face {
  font-family: 'halyard-light';
  src: url(../fonts/halyard/Halyard-Micro-Light.ttf);
}
@font-face {
  font-family: 'halyard-regular';
  src: url(../fonts/halyard/Halyard-Text-Regular.ttf);
}
@font-face {
  font-family: 'instrument-medium';
  src: url(../fonts/instrument/InstrumentSans-Medium.ttf);
}
@font-face {
  font-family: 'instrument-regular';
  src: url(../fonts/instrument/InstrumentSans-Regular.ttf);
}

h1 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin: 0;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}
h4 {
  font-family: 'instrument-medium';
  font-size: 0.8rem;
  font-weight: 100;
  color: #333333;
}
h6 {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 0.65rem;
  letter-spacing: -0.02rem;
  color: #848382;
}
h6.f-20 {
  font-size: 0.9rem;
  color: #000;
  font-family: 'instrument-medium';
}
.container{
  max-width: 1920px;
  margin: auto;
}
.padding-x-all{
  padding: 0 6.8rem;
}
.sec-com-padding{
  padding: 6.5rem 0;
}
.relative{
  position: relative;
}
.flex-box{
  display: flex;
  flex-wrap: wrap;
}
.space-between{
  justify-content: space-between;
}
.align-center{
  align-items: center;
}
.selected {
  background: #fff !important;
  filter: invert(1);
}
.selected h6 {
  color: #000;
}

.edit-btn-box {
  text-align: end;
  margin-right: -0.2rem;
  margin-top: -0.9rem;
}
.edit-btn-box img {
  width: 0.8rem;
  height: 1.1rem;
  object-fit: contain;
  background: #000;
  padding: 0.22rem 0.4rem;
  border-radius: 5rem;
  cursor: pointer;
}
.customer-name-box img {
  width: 2.1rem;
  margin-right: 0.4rem;
  border-radius: 5rem;
}
.customer-name-box h4 {
  font-size: 0.65rem;
}
.customer-name-box h6 {
  font-size: 0.55rem;
  margin-bottom: 0.2rem;
}
.customer-detail-box {
  margin-top: 0.5rem;
}
.customer-contact-detail-box h4 {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 0.55rem;
}
.customer-contact-detail-box h4 span {
  margin-right: 0.4rem;
  display: block;
}
.customer-contact-detail-box img {
  width: 0.7rem;
  height: 0.7rem;
  object-fit: contain;
}
.customer-contact-detail-box h6 {
  background: #000;
  width: max-content;
  padding: 0.05rem 0.4rem;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.45rem;
  letter-spacing: 0;
  margin-left: 1rem;
  margin-bottom: 0.2rem;
}
.customer-contact-detail-box {
  padding-bottom: 1rem;
  margin-bottom: 0.6rem;
  background: radial-gradient(50% 0.5% at 50% 95%, #d3d3d3 0%, rgba(80, 28, 28, 0) 100%);
}
.customer-name-box.flex-box.align-center {
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  background: radial-gradient(50% 0.6% at 50% 95%, #d3d3d3 0%, rgba(80, 28, 28, 0) 100%);
}

.main-menu-box {
  background: #F6F907;
  padding: 1.7rem 1rem;
  border-radius: 1.3rem;
  width: 11.5%;
  height: 36rem;
}
.main-dashboard-box {
  padding: 2rem;
}
ul.menu-listing li .tab-box {
  padding: 0.6rem 0.8rem;
  border-radius: 0.5rem;
  z-index: 10;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.2rem;
}
ul.menu-listing li .tab-box:hover {
  background: #ffffff;
}
ul.menu-listing li .tab-box.selected, ul.menu-listing li .tab-box.pre-selected {
  background: #fff !important;
  filter: invert(1);
}
ul.menu-listing li .tab-box.grey-bg {
  background: #fff;
  filter: invert(1);
}
ul.menu-listing li .tab-box.selected h6, ul.menu-listing li .tab-box.grey-bg h6, ul.menu-listing li .tab-box.pre-selected h6 {
  color: #000;
  font-weight: 600;
}
ul.menu-listing li .tab-box img {
  object-fit: contain;
  width: 1rem;
  height: 1rem;
}
ul.menu-listing li .tab-box h6 {
  display: flex;
  align-items: center;
  color: #000;
  font-family: 'instrument-medium';
}
ul.menu-listing li .tab-box h6 span {
  margin-right: 0.8rem;
}
h6.menu-sub-text {
  text-transform: uppercase;
  font-size: 0.55rem;
  margin-bottom: 0.4rem;
  margin-left: 0.6rem;
}
.tab-detail-outer-box {
  width: 84.3%;
}
h6.tab-detail-heading {
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  letter-spacing: 0;
}
h6.tab-detail-heading span {
  background: yellow;
  font-size: 0.5rem;
  margin-left: 0.4rem;
  padding: 0.1rem 0.7rem;
  border-radius: 2rem;
}
ul.all-orders-listing li .order-box:hover {
  background: #fbfbfb;
}
ul.all-orders-listing li .order-box h6 {
  margin-bottom: 0.3rem;
}
ul.all-orders-listing li .order-box {
  background: #fff;
  padding: 1.4rem 1.7rem;
  border-radius: 0.5rem;
  margin-bottom: 0.7rem;
  text-decoration: none;
}
ul.all-orders-listing li .order-box .order-detail-box {
  border-right: 1px solid #D9D9D9;
  margin-right: 1.5rem;
}
ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 8%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 16%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 9.5%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 10%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 15%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(6) {
  border: none;
  width: 11%;
  margin-right: 0;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(6) h4{
  position: relative;
  width: max-content;
}
.status-dot {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -15%;
  right: -11%;
}
.orange {
  background: #FFC738;
  box-shadow: 0px 0px 6px 0px #FFC738;
}
.green {
   background: #1BD038;
   box-shadow: 0px 0px 6px 0px #1BD038;
}
.red{
  background: #FF3838;
  box-shadow: 0px 0px 6px 0px #FF3838;
}

.order-cta-box img {
  width: 1.3rem;
  object-fit: contain;
}
.order-cta-box {
  width: 2.2rem;
  height: 2.2rem;
  background: #f9f92f;
  border-radius: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-inner-listing {
  position: relative;
  padding-left: 2rem;
  height: 0;
  overflow: hidden;
  margin: 0;
}
.tab-box.hide+div {
  height: 0;
  padding-top: 0;
}
.menu-inner-listing.full-height {
  height: 100%;
  padding-top: 0.2rem;
}
.menu-inner-tab-box {
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  cursor: pointer;
}
.menu-inner-tab-box h6 {
  font-family: 'instrument-medium';
  color: #000;
}
.menu-inner-tab-box:hover{
  background-color: #ffffff;
}
.menu-inner-listing::after{
  content: '';
  position: absolute;
  background: url(../img/connection-icon.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: 10%;
  top: -1%;
  width: 1.5rem;
  height: 87%;
}
.user-order-listing-outer-box {
  width: 79.7%;
  background: #fff;
  border-radius: 0.6rem;
  padding: 1rem 1.9rem;
}
.user-detail-box .head-box {
  margin-bottom: 0.6rem;
}
.user-detail-box h6.tab-detail-heading {
  font-size: 0.9rem;
  font-family: 'instrument-regular';
}
ul.user-detail-listing li {
  width: max-content;
  display: flex;
  flex-flow: column;
}
ul.user-detail-listing li.track-your-order-box {
  width: max-content;
  align-items: center;
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
}
ul.user-detail-listing li.track-your-order-box .text-center {
  background: #D6D1C4;
  padding: 0.2rem 1.5rem;
  border-radius: 1rem;
  margin-bottom: -0.8rem;
  z-index: 1;
}
.list-text-box {
  padding-left: 0.8rem;
}
ul.user-detail-listing li.track-your-order-box .inner-list-box {
  border: 1px solid #E1E1E1;
  border-radius: 1.2rem;
  padding: 1.3rem 1.8rem;
}
ul.user-detail-listing li.track-your-order-box .list-box {
  margin-right: 1.6rem;
  padding-right: 1.8rem;
  border-right: 1px solid #D6D1C4;
  display: flex;
  align-items: center;
}
ul.user-detail-listing li.track-your-order-box .list-box:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
ul.user-detail-listing li.track-your-order-box .list-box:nth-child(2) .status-dot{
  right: -5%;
}
ul.user-detail-listing li.track-your-order-box .list-box .status-dot{
    right: -20%;
}
ul.user-detail-listing li.track-your-order-box img {
  width: 4.3rem;
  margin-right: 0.7rem;
}
ul.user-detail-listing li.track-your-order-box h4 {
  font-weight: 600;
  width: max-content;
}
ul.user-detail-listing li.track-your-order-box h6 {
  text-transform: uppercase;
  margin-top: 0.3rem;
  margin-bottom: 0.1rem;
  font-size: 0.58rem;
  letter-spacing: 0;
}
ul.user-detail-listing li.track-your-order-box h5 {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 0.75rem;
}
ul.user-detail-listing li .text-box {
  display: flex;
  align-items: center;
  margin: 0.4rem 0;
}
ul.user-detail-listing li .text-box h6 {
  margin-right: 0.3rem;
  font-size: 0.85rem;
}
ul.user-detail-listing li .text-box h4 {
  font-size: 0.85rem;
  font-family: 'instrument-regular';
  font-weight: 700;
}
h4#price-info {
  display: flex;
  align-items: center;
}
h4#price-info span img {
  object-fit: contain;
  width: 0.8rem;
  margin-left: 0.2rem;
  margin-bottom: -0.1rem;
  cursor: pointer;
}
.price-info-box {
  display: none;
  position: absolute;
  right: -6.8rem;
  top: -5.2rem;
  background: #fff;
  box-shadow: 0px 18px 22px 0px #0000000D;
  border: 0.5px solid #E2E2E2;
  padding: 0.4rem 0.5rem;
  width: 6.5rem;
  border-radius: 0.7rem 0.7rem 0.7rem 0;
}
.price-info-box.show{
  display: block;
}
ul.user-detail-listing li .price-info-box .text-box {
  display: flex;
  align-items: center;
  margin: 0;
  height: 0.8rem;
}
ul.user-detail-listing li .price-info-box h6 {
  font-size: 0.45rem;
  margin-bottom: 0;
  margin-right: 0;
}
.price-info-box .text-box h6:first-child {
  width: 70%;
}
.price-info-box .text-box h4 {
  margin: 0 0.3rem;
}
.price-info-box .text-box h6:last-child {
  width: 30%;
}
li.item-detail-box {
  margin: 1.2rem 0;
  border-radius: 1rem;
  border: 1px solid #E1E1E1;
}
li.item-detail-box .head-box h6 img {
  margin-left: 1rem;
  width: 0.9rem;
  object-fit: contain;
  transition: 0.3s all;
}
li.item-detail-box .head-box h6 img.rotate{
  transform: rotateZ(180deg);
  transition: 0.3s all;
}
ul.item-detail-inner-box-listing {
  margin: 0.7rem 0rem;
  margin-bottom: 0;
  padding: 1.2rem 1.1rem;
  padding-top: 0;
  margin-top: -0.5rem;
  transition: 0.3s all;
}
li.item-detail-inner-box {
  width: 14.5%;
  border: 1px solid #D9D9D9;
  padding: 1rem 1.5rem;
  margin: 0.85rem;
  margin-right: 0.45rem;
  border-radius: 0.7rem;
}
li.item-detail-inner-box:nth-child(2) {
  width: 71%;
  margin-right: 0;
}
li.item-detail-inner-box .text-box {
  width: 60%;
}
li.item-detail-inner-box .img-box {
  width: 37%;
}
li.item-detail-inner-box .img-box h6{
  margin-bottom: 0.6rem;
}
li.item-detail-inner-box video, li.item-detail-inner-box img {
  width: 8rem;
  object-fit: contain;
}
.item-bottom-bar {
  width: 100%;
}
.status-and-date-box {
  display: flex;
  align-items: center;
}
.item-bottom-bar {
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.7rem;
  padding-left: 1rem;
}
.status-and-date-box img {
  width: 1rem;
  height: 0.9rem;
  object-fit: contain;
  margin-left: 1.5rem;
  margin-right: 0.3rem;
}
.status-and-date-box h6 {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
.status-and-date-box h4 {
  background: #DCFFDB;
  padding: 0.1rem 0.7rem;
  margin-left: 0.6rem;
  border-radius: 1rem;
}
.item-bottom-bar a {
  color: #969999;
  text-underline-offset: 0.3rem;
}
li.item-detail-inner-box .field-box {
  margin-bottom: 0.9rem;
}
li.item-detail-inner-box .field-box:last-child {
  margin-bottom: 0;
}
li.item-detail-inner-box .field-box h6 {
  margin-bottom: 0.3rem;
}
li.item-detail-inner-box .field-box h4 {
  line-height: 1rem;
}
li.item-detail-inner-box .field-box h6.address {
  font-size: 0.7rem;
  margin-top: 0.3rem;
  width: 80%;
}
li.item-detail-inner-box .field-box video {
  width: 4rem;
  object-fit: contain;
}
li.item-detail-inner-box .field-box img {
  width: 3.5rem;
  object-fit: contain;
}
ul.items-listing li.item-detail-box ul.item-detail-inner-box-listing.show {
  display: none;
  transition: 0.3s all;
}
li.item-detail-box .head-box {
  cursor: pointer;
  padding: 1.25rem 1.8rem;
}
li.item-detail-box .head-box h6.f-20 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.13rem;
  letter-spacing: -0.005rem;
  -webkit-text-stroke-width: 0.02rem;
}
li.item-detail-inner-box .field-box .img-box img {
  object-fit: contain;
  width: 2.4rem;
  margin-right: .5rem;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box {
  font-size: 0.3rem;
  color: #D9D9D9;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box h4 {
  width: 30%;
  font-size: 0.6rem;
  text-align: end;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box h6 {
  width: 35%;
  font-size: 0.5rem;
}
li.item-detail-inner-box .field-box.item-amount-box .text-head-box {
  margin-bottom: 0.2rem;
}

/* payment css  */

.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 9%;
}
.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 10%;
}
.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 15.5%;
}
.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 20%;
}
.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 27%;
  border-right: 0;
}
h6.tab-detail-heading img {
  height: 0.7rem;
  margin-right: 0.3rem;
  object-fit: contain;
  width: 1.3rem;
}
h4.export-csv-btn {
  margin-left: 2rem;
}
.payment-listing-box select {
  background: #0000;
  border: none;
  border-left: 1px solid #a8a8a8;
  color: #333;
  cursor: pointer;
  font-family:"instrument-medium";
  font-size: .8rem;
  margin: 0 1rem;
  padding-left: 1rem;
}
.payment-listing-box select option {
  background: #fff;
  color: #333;
  font-family:"instrument-medium";
  font-size: .8rem;
}
.payment-listing-box .head-box {
  margin-bottom: 0.3rem;
}
.popup-outer-box {
  align-items: center;
  background: #000000b3;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
}
.popup-box {
  background: #fff;
  border-radius: .6rem;
  padding: 2rem 1.5rem;
  position: relative;
}
button.popup-close-btn {
  background: #e2e21a;
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  height: 2rem;
  margin: 0;
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 2rem;
}
button.popup-close-btn img {
  height: .7rem;
  margin-bottom: -.1rem;
  object-fit: contain;
  width: .8rem;
}
.popup-box h6 {
  margin-bottom: .3rem;
}
.bottom-save-box {
  margin-bottom: -.8rem;
}
button.save-info-btn {
  background: #e2e21a;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-family: "instrument-medium";
  font-size: .7rem;
  margin-top: 1rem;
  padding: .5rem .2rem;
  width: 5rem;
}
.payment-popup .inner-box {
  width: 27rem;
  margin-bottom: 1.5rem;
}
.payment-popup .inner-box .field-box:first-child {
  border-right: 1px solid #D9D9D9;
  width: 23%;
}
.payment-popup .inner-box .field-box:nth-child(2) {
  width: 32%;
  border-right: 1px solid #D9D9D9;
}
.payment-popup .inner-box .field-box:nth-child(3) {
  width: 34%;
}
.bottom-box h4 {
  margin: 0.3rem 0;
}
.bottom-box h6 {
  margin-bottom: 0.5rem;
}

.img-change-box {
  width: 30%;
}
.img-change-box img.user-edit-img {
  border-radius: 10rem;
  height: 6rem;
  margin-right: 2.7rem;
  object-fit: cover;
  width: 6rem;
}
.img-change-box img.user-change-img {
  cursor: pointer;
  object-fit: contain;
  position: absolute;
  right: 5%;
  top: 5%;
  width: 1.2rem;
}
.user-text-box {
  width: 57%;
}
.user-text-box .field-box {
  margin-bottom: .8rem;
}
.user-text-box .field-box h4 {
  font-size: .65rem;
}
.user-text-box .input-box {
  align-items: center;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  margin-top: .5rem;
}
.user-text-box .input-box input {
  border: none;
  border-radius: .2rem;
  font-family: "instrument-regular";
  font-size: .65rem;
  padding: .5rem .3rem;
  width: 71%;
}
.user-text-box .input-box button {
  background: #0000;
  border: none;
  cursor: pointer;
  font-family: "instrument-medium";
  font-size: .62rem;
  padding-right: .5rem;
}
.address-edit-box input {
  border: 1px solid #dbdbdb;
  border-radius: .2rem;
  font-family: "instrument-regular";
  font-size: .65rem;
  padding: .5rem .3rem;
  width: 100%;
}
.contact-tab-box .faq-main-box {
  background: #fff;
  width: 74.3%;
  padding: 1.2rem 1.4rem;
  border-radius: 0.4rem;
}
.contact-tab-box .faq-main-box h4, .contact-tab-box .track-box .text-box h4, .contact-tab-box .shop-box h4 {
  font-size: 0.9rem;
  font-family: 'instrument-regular';
  font-weight: 600;
}
.contact-tab-box .faq-main-box .faq-box {
  margin-top: 1rem;
  width: 80%;
}
.contact-tab-box .faq-main-box .faq-box h6 {
  font-size: 0.88rem;
  margin-top: 0.3rem;
}
.contact-tab-box .order-side-box {
  width: 20%;
}
.contact-tab-box .track-box img {
  width: 2.5rem;
  object-fit: contain;
}
.contact-tab-box .track-box {
  background: #fff;
  padding: 1.5rem 1.8rem;
  padding-right: 0.3rem;
  border-radius: 0.4rem;
}
.contact-tab-box .track-box .text-box {
  width: 77%;
}
.btn h6 {
  font-weight: 700;
  color: #000;
  background: #E2E21A;
  width: max-content;
  padding: 0.5rem 0.9rem;
  margin-top: 0.8rem;
  border-radius: 2rem;
}
.btn {
  text-decoration: none;
  cursor: pointer;
}
.contact-tab-box .shop-box a h6 {
  width: 4.5rem;
}
.contact-tab-box .shop-box {
  padding: 2rem;
  padding-bottom: 11rem;
  border-radius: 0.4rem;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 1rem;
  background: url(../img/shop-bg.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact-tab-box h6.tab-detail-heading img{
  height: 0.9rem;
}
.contact-bottom-box {
  background: #fff;
  margin-top: 1rem;
  padding: 1.5rem 2.5rem;
  border-radius: 0.4rem;
}
.contact-bottom-box {
  background: #fff;
  margin-top: 1rem;
  padding: 1.5rem 2.5rem;
  border-radius: 0.4rem;
}
.contact-bottom-box img {
  width: 2.2rem;
  margin-right: 0.7rem;
}
.contact-bottom-box h6 {
  margin-bottom: 0.3rem;
}
.contact-bottom-box .contact-box .i-mess-box h4 {
  display: flex;
  align-items: center;
  border: 1px solid #C9C9C9;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
}
.contact-bottom-box .contact-box .i-mess-box h4 img {
  width: 1.4rem;
  object-fit: contain;
  height: 1.4rem;
}

/* setting tabs  */

.settings-tabs {
  background: #fff;
  padding: 0.5rem 2rem;
  padding-bottom: 1.5rem;
  border-radius: 0.4rem;
  height: 87.5%;
}
.settings-tabs .head-box {
  border-bottom: 1px solid #F4F5F7;
  margin-bottom: 1.8rem;
}
.settings-tabs .tab-menu {
  margin-right: 2rem;
  margin-left: 2rem;
  cursor: pointer;
  border-bottom: 3px solid #f6f90700;
}
.settings-tabs .tab-menu h6{
  color: #ACACAC;
}
.settings-tabs .tab-menu.active h6 {
  color: #000;
}
.settings-tabs .tab-menu.active {
  border-bottom: 3px solid #F6F907;
}
.settings-tabs .logout-box h6{
  cursor: pointer;
}
.setting-content p {
  margin-bottom: 0.6rem;
  font-family: 'instrument-medium';
  font-size: 0.8rem;
  font-weight: 100;
  color: #333333;
  line-height: 1.2rem;
}
.setting-content h4 {
  line-height: 1.2rem;
  font-family: 'instrument-regular';
  font-weight: 600;
  margin-bottom: 0.8rem;
  margin-top: 1rem;
}
.setting-content li {
  font-family: 'instrument-medium';
  font-size: 0.8rem;
  font-weight: 100;
  color: #333333;
  margin-bottom: 0.8rem;
  list-style: disc;
  margin-left: 1rem;
  line-height: 1.2rem;
}
.notification-box h4.deactive-btn span {
  justify-content: start;
  background: #DFEAF2;
}
.notification-box h4 {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
}
.notification-box h4 span {
  width: 2rem;
  background: yellow;
  border-radius: 3rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0.1rem;
  cursor: pointer;
  box-shadow: 0px 0px 8px 2px #0000000d;
}
.notification-box h4 span img {
  width: 1rem;
  object-fit: contain;
  box-shadow: 0px 2px 5px 0px #0000001A;
  border-radius: 5rem;
}
.notification-box h6 {
  color: #000;
}

.address-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(6) {
  border: none;
  width: 23%;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 12%;
  border-right: 0;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 10%;
  border: none;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 12.5%;
  border: none;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 10%;
  border: none;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 6%;
  border: none;
}
.order-cta-box.empty-box {
  background: transparent;
}
.address-head-box {
  padding: 0.4rem 1.7rem !important;
  border-radius: 0.4rem 0.4rem 0 0 !important; 
}
.address-listing-box ul.all-orders-listing li .order-box{
  border-bottom: 1px solid #D5D5D5;
  margin-bottom: 0;
  border-radius: 0;
}
.address-listing-box ul.all-orders-listing li:last-child .order-box {
  border-radius: 0 0 0.4rem 0.4rem; 
}
.address-listing-box .field-box {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 0.6rem;
  margin-bottom: 0.8rem;
}
.address-listing-box .field-box .value-box {
  width: 50%;
}
.address-listing-box .field-box:last-child {
  border-bottom: none;
}
h6.bottom-star-text {
  text-transform: uppercase;
  font-size: 0.5rem;
}
.address-listing-box .popup-box {
  padding: 1.8rem 2.3rem;
}

/* login css  */

.login-main-box h2 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 2.1rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}
.login-main-box p {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.1rem;
  letter-spacing: 0rem;
}
.login-right-box .form-box .feild-box, .login-right-box .otp-box .feild-box {
  margin-top: 1rem;
  flex-flow: column;
}
.form-box .feild-box input, input.otp-input {
  padding: 0.5rem 0.3rem;
  padding-left: 0.8rem;
  font-family: 'instrument-regular';
  border: 1px solid #B5B5B5;
  border-radius: 0.4rem;
}
.show-otp-box {
  display: block !important;
}
.hide-form-box{
  display: none !important;
}

/* new edit for login  */

.login-left-box {
  background: url(../img/log-in-left-bg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: end;
  height: 79vh;
  border-radius: 1rem;
  padding: 2rem 2.3rem;
  width: 38%;
  display: flex;
  flex-flow: column;
}

.login-main-box {
  padding: 2rem;
  height: max-content;
  position: relative;
}
.login-main-box::after{
  content: '';
  position: absolute;
  background: url(../img/login-after.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 4%;
  top: 6%;
  width: 6rem;
  height: 3rem;
}
.login-left-box .text-box {
  background: linear-gradient(100.08deg, rgb(88 88 88 / 77%) -4.89%, rgb(99 99 99) 105.72%);
  box-shadow: 0px 26.36px 39.93px 0px #0000001A;
  padding: 2rem 1.8rem;
  padding-top: 1rem;
  border-radius: 1rem;
}
.login-main-box h2{
  font-size: 1.8rem;
}
.login-main-box p {
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: #fff;
}
.login-main-box h2{
  color: #ffffff;
}
.login-right-box {
  width: 44%;
}
.login-right-box h1 {
  font-size: 1.6rem;
  -webkit-text-stroke-width: 0.01rem;
  letter-spacing: -0.1rem;
  line-height: 3rem;
}
.login-right-box h6 {
  font-size: 1.05rem;
}
.login-right-box .form-box .feild-box{
  align-items: start;
}
.form-box .feild-box input, input.otp-input {
  width: 65%;
  margin-top: 0.4rem;
  height: 1.8rem;
  background-color: transparent;
  font-size: 0.85rem;
}
.form-box .feild-box h4, .otp-box h4 {
  margin-top: 1rem;
  color: #5A5A5A;
}
.form-box button.save-info-btn, .otp-box button.save-info-btn {
  height: 3rem;
  border-radius: 0.5rem;
  width: 7.5rem;
  font-weight: 700;
  font-size: 0.86rem;
  font-family: 'halyard-regular';
  margin-top: 1.7rem;
}
.login-right-box .otp-box .feild-box+p{
  font-family: 'instrument-regular';
  font-weight: 100 !important;
  margin-top: 0.7rem;
}



@media all and (min-width: 1920px) {
  html {
    font-size: 26px;
  }
}   
@media all and (max-width: 1920px) {
  html {
    /* font-size: 1.4vw; */
    font-size: 1.15vw;
  }
}
@media all and (min-width: 767px) {
  .desktop-none{
    display: none !important;
  }
  .mobile-menu-box{
   display: none !important;
  }
  .mobile-close-btn-box{
   display: none !important;
 }
 
}  
@media all and (max-width: 767px) {

 html{
   font-size: 3.2vw;
 }
 h4 {
   font-size: 1.1rem;
 }
 h6 {
   font-size: 0.8rem;
}

 .login-main-box {
     padding: 1rem;
 }
 .login-right-box {
     width: 100%;
 }
 .login-left-box {
   width: 100%;
   height: 21rem;
   padding: 1.5rem;
 }
 .login-left-box .text-box {
   padding: 1rem 1.2rem;
 }
 .login-main-box h2 {
   font-size: 2.3rem;
   font-weight: 100;
 }
 .login-main-box p {
   font-size: 1.2rem;
   line-height: 1.5rem;
   font-family: 'instrument-regular';
 }
 .login-right-box h1 {
   font-size: 3.7rem;
   line-height: 3.5rem;
   font-family: 'halyard-light';
   margin-top: 2rem;
   letter-spacing: -0.4rem;
   font-weight: 300;
   -webkit-text-stroke-width: 0.05rem;
}
.form-box .feild-box input, input.otp-input {
 width: 95%;
 margin-top: 0.8rem;
 font-size: 1rem;
 height: 3rem;
}
img.smile-emoji {
 width: 2rem !important;
}
.form-box button.save-info-btn, .otp-box button.save-info-btn {
 font-size: 1rem;
 width: 10rem;
}
.login-right-box .otp-box .feild-box+p {
 font-family: 'instrument-regular';
 font-weight: 100 !important;
 font-size: 1rem !important;
 margin-top: 0.7rem;
}
.main-dashboard-box {
 padding: 0rem;
}
.main-menu-box {
 height: 100vh;
 width: 0;
 padding: 0rem;
 z-index: 5;
 overflow: hidden;
 border-radius: 0;
}
.main-menu-box.open-mobile-menu {
 width: 100%;
 transition: 0.1s all;
}
.main-menu-box.open-mobile-menu+div{
 display: none;
}
.tab-detail-outer-box {
 width: fit-content;
 padding: 1rem;
 margin-top: 5rem;
}
h6.tab-detail-heading {
 font-size: 1.2rem;
}
h6.tab-detail-heading span {
 font-size: 0.75rem;
 padding: 0.3rem 0.9rem;
 margin-left: 0.7rem;
}
ul.all-orders-listing li .order-box {
 margin-bottom: 1rem;
 padding: 1rem;
 padding-right: 1rem;
 padding-bottom: 0;
 position: relative;
}
ul.all-orders-listing li .order-box .order-detail-box {
 margin-bottom: 2rem;
 max-height: 7rem;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(4), ul.all-orders-listing li .order-box .order-detail-box:first-child {
 /* width: 5rem; */
 width: 7rem;
 /* margin-right: 1rem; */
 margin-right: 0.6rem;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(5), ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
 width: 10rem;
 margin-right: 1rem;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(6), ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
 width: 7rem;
 margin-right: 0;
 border-right: 0;
}
ul.all-orders-listing li .order-box h6 {
 margin-bottom: 0.6rem;
}
.order-cta-box {
 position: absolute;
 right: 1.6rem;
 top: 41%;
 display: none;
}
.mobile-menu-box {
 width: -webkit-fill-available;
 height: 3rem;
 position: fixed;
 top: 0;
 background: #000;
 padding: 1rem;
 z-index: 5;
}
.customer-name-box.flex-box.align-center {
 padding: 0;
 margin: 0;
 background: transparent;
}
.customer-name-box h6 {
 font-size: 0.65rem;
 margin-bottom: 0.2rem;
 color: #fff;
 letter-spacing: 0.05rem;
}
.customer-name-box h4 {
 font-size: 0.9rem;
 color: #FFF;
 font-family: 'instrument-regular';
 letter-spacing: 0.01rem;
} 
.customer-name-box img {
 /* margin-right: 0.6rem; */
 /* width: 2.5rem; */
 width: 100%;
 margin-right: 0;
}

.mobile-hamburger {
 color: #fff;
 font-size: 2.2rem;
 cursor: pointer;
}
.edit-btn-box, .customer-detail-box {
 display: none;
}
ul.menu-listing {
 padding: 1rem;
 border-top: 1px solid #000;
}
h6.menu-sub-text {
 padding: 1rem;
 font-size: 1rem;
 margin-top: 1rem;
}
.mobile-close-btn-box {
 font-family: 'instrument-medium';
 font-size: 2.5rem;
 margin-right: 1rem;
 margin-top: 0.8rem;
 width: max-content;
 float: right;
}
ul.menu-listing li .tab-box {
 padding: 1.3rem 1rem;
}
ul.menu-listing li .tab-box h6 {
 font-size: 1.2rem;
}
ul.menu-listing li .tab-box img {
 width: 1.5rem;
 height: 1.5rem;
}
.menu-inner-tab-box h6 {
 font-size: 1.1rem;
}
.menu-inner-tab-box {
 padding: 1.2rem 1rem;
 border-radius: 0.5rem;
 margin: 0.1rem 0;
 cursor: pointer;
}
.menu-inner-listing{
 padding-left: 3rem;
}
.menu-inner-listing::after {
 left: 1rem;
}
.user-order-listing-outer-box {
 width: -webkit-fill-available;
 padding: 1.2rem;
}
.popup-box h6{
 margin-bottom: 0;
}
.address-listing-box .popup-box {
 padding: 1.8rem 1.8rem;
 width: 75%;
}
button.popup-close-btn img {
 height: 1rem;
 width: 1rem;
}
button.popup-close-btn {
 width: 2.5rem;
 height: 2.5rem;
}
h6.bottom-star-text {
 font-size: 0.8rem;
}
.popup-box .title-box h6 {
 font-size: 1rem;
}
.contact-tab-box h6.tab-detail-heading img, h6.tab-detail-heading img {
 height: 1.5rem;
 margin-right: 0.5rem;
}
.contact-tab-box .faq-main-box h4, .contact-tab-box .track-box .text-box h4, .contact-tab-box .shop-box h4 {
 font-size: 1.3rem;
}  
.contact-tab-box .faq-main-box .faq-box h6 {
 font-size: 1.1rem;
 margin-top: 0.7rem;
}
.contact-tab-box .faq-main-box .faq-box {
 margin-top: 1.3rem;
 width: 100%;
}
.contact-tab-box .faq-main-box {
 width: 100%;
 padding: 1.5rem;
}
.contact-tab-box .order-side-box {
 width: 100%;
 margin-top: 1rem;
}
.contact-tab-box .track-box {
 align-items: center;
}
.contact-tab-box .track-box img {
 width: 4.5rem;
}
.btn h6 {
 padding: 0.5rem 2rem;
}
.contact-bottom-box {
 padding: 1.5rem;
 padding-bottom: 0.5rem;
}
.contact-bottom-box .contact-box {
 margin-bottom: 1rem;
}
.contact-bottom-box img {
 width: 2.6rem;
 margin-right: 1rem;
}
.contact-bottom-box .contact-box .i-mess-box h4 img {
 width: 1.9rem;
 height: 1.6rem;
}
.contact-bottom-box h6 {
 margin-bottom: 0.4rem;
}
.settings-tabs .tab-menu h6 {
 font-size: 0.9rem;
}
.settings-tabs {
 width: -webkit-fill-available;
 padding: 1.2rem;
 height: max-content;
}
.settings-tabs .tab-menu {
 margin-right: 0rem;
 margin-left: 1rem;
}
.settings-tabs .tab-menu:first-child {
 margin-left: 0;
}
.settings-tabs .logout-box h6 {
 position: absolute;
 top: 6rem;
 right: 2rem;
 font-size: 1rem;
}
.settings-tabs .logout-box h6 img {
 width: 1rem;
}
.setting-content p, .setting-content li {
 font-size: 1.2rem;
 line-height: 1.6rem;
 margin-bottom: 1rem;
}
.setting-content h4 {
 font-size: 1.4rem;
 line-height: 1.7rem;
 margin-bottom: 1rem;
 margin-top: 1.5rem;
}
.notification-box h6 {
 font-size: 1.2rem;
}
.notification-box h4 span{
 padding: 0.15rem;
 width: 2.2rem;
 margin-right: 1rem;
}
.notification-box h4 span img {
 width: 1.1rem;
}
.user-detail-box h6.tab-detail-heading {
  font-size: 1.2rem;
  margin-bottom: 0;
}
ul.user-detail-listing li .text-box h6 {
  margin-right: 1rem;
  font-size: 1.1rem;
}
ul.user-detail-listing li .text-box h4 {
  font-size: 1.1rem;
}
h4#price-info span img {
  width: 1.1rem;
  margin-left: 0.5rem;
  margin-bottom: -0.2rem;
}
.price-info-box {
  width: 14rem;
  right: -8rem;
  top: -7rem;
}
ul.user-detail-listing li .price-info-box .text-box {
  height: 1.3rem;
}
ul.user-detail-listing li.track-your-order-box {
  margin-top: 2rem;
}
ul.user-detail-listing li .price-info-box h6 {
  font-size: 0.8rem;
}
ul.user-detail-listing li.track-your-order-box .list-box {
  width: 100%;
  border-right: 0;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
}
ul.user-detail-listing li.track-your-order-box img {
  width: 5rem;
  margin-right: 1rem;
}
ul.user-detail-listing li.track-your-order-box h4 {
  font-size: 1.3rem;
}
ul.user-detail-listing li.track-your-order-box h5 {
  font-size: 1rem;
  margin-top: 0.2rem;
}
ul.user-detail-listing li.track-your-order-box h6 {
  font-size: 0.8rem;
}
li.item-detail-box{
  margin: 0.8rem 0;
}
li.item-detail-inner-box {
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  padding: 1.2rem;
}
li.item-detail-inner-box:nth-child(2){
  width: 100%;
}
li.item-detail-inner-box .text-box {
  width: 100%;
}
li.item-detail-inner-box .img-box {
  width: 100%;
  margin-top: 0.8rem;
}
li.item-detail-inner-box .field-box h4 {
  line-height: 1.3rem;
}
li.item-detail-inner-box video, li.item-detail-inner-box img {
  width: 11.4rem;
  margin-top: 0.5rem;
}
.item-bottom-bar {
  margin-top: 1rem;
  margin-bottom: 0;
}
.status-and-date-box{
  flex-wrap: wrap;
}
.item-bottom-bar a{
  margin-bottom: 0.8rem
}
.status-and-date-box img {
  margin-left: 0;
}
.status-and-date-box h4 {
  font-size: 1rem;
  margin-right: 2rem;
  margin-top: 0.7rem;
}
.status-and-date-box h6{
  margin-top: 0.7rem;
  font-size: 1rem;
}
li.item-detail-box .head-box h6.f-20 {
  font-size: 1.2rem;
}
li.item-detail-box .head-box {
  padding: 1.45rem 1.8rem;
}
ul.user-detail-listing {
  margin-top: 3rem;
}
.payment-listing-box select {
  font-size: 1rem;
  padding-left: 0.1rem;
  margin-left: 0.7rem;
  width: 6.5rem;
}
h4.export-csv-btn {
  margin-left: 1rem;
  font-size: 1rem;
}


.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box {
  width: 8.3rem !important;
  margin-bottom: 0;
  margin: 0.5rem 0;
}
.address-listing-box ul.all-orders-listing li .order-box {
  padding: 1rem 1.9rem !important;
  justify-content: start;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-detail-box h4 {
  font-size: 1rem;
}
.address-listing-box.payment-listing-box ul.all-orders-listing li .order-box .order-cta-box {
  display: none;
}
}